.homePage-structure {
}

.headerRegister-layout {
  border-bottom: 1px #e5e5e5 solid !important;
}

.headerRegister-layout.home-page {
  border-bottom: none !important;
}

.home-header {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}

.home-header-txt {
  color: white;
}

.home-subTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16vh;
}

.home-subTitle-line {
  border: 1px solid #a4b3f4;
  margin-top: 3vh;
  opacity: 0.3;
}

.home-body-cart-title {
  color: #ffffff;
  /*font-family: 'Inter';*/
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-left: -12px;
}

.home-body-cart-titlePrice {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #a4b3f4;
}

.home-carousel {
  display: flex;
  flex-direction: column;
}

.home-carousel-image {
  /*width: 77vw !important;*/
  display: flex;
  flex-direction: column;
}

.ant-avatar-string {
  font-size: 22px;
}

.slick-slider {
  margin-right: 57px !important;
}

.home-body-cart-amount {
  width: 100%;
  height: 190px;
  background: linear-gradient(
    156.1deg,
    rgba(255, 255, 255, 0.28) -6.77%,
    rgba(255, 255, 255, 0.105) 124.71%
  );
  opacity: 0.8;
  box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(34px);
  border-radius: 8px;
  border: 1px solid cornflowerblue;
  padding: 15px 20px 0 20px;
}

.home-subTitle-btn {
  background: #026bff;
  border-radius: 4px;
  border: 1px #026bff solid;
  color: #ffffff;
  height: 40px;
}

.slick-slide .slick-active .slick-current {
  width: 125px !important;
}

.slick-slide {
  /*width: 197px !important;*/
}

.home-body-cart-price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  align-items: center;
  color: #ffffff;
}

.ant-progress-bg {
  background: #03bc7d;
  border-radius: 12px;
}

.ant-progress-text {
  color: #03bc7d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  width: 0;
  align-items: center;
  text-align: right;
  margin-left: 50%;
}

.home-carousel-items {
  display: flex !important;
  flex-direction: column;
  width: 47% !important;
  user-select: none;
  cursor: pointer;
}

.home-carousel-image-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
}

.home-carousel-image-view {
  /*font-family: 'Inter';*/
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #026bff;
  margin-bottom: 10px;
}

.home-footer-copyText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #f0f0f0;
  margin-left: 42%;
  margin-top: 6px;
}

.homePage-footer-titleList {
  color: white;
  font-weight: bold;
  font-size: 20px;
  width: 123px;
}

.slick-dots li button:before {
  color: #ffffff !important;
}

@media screen and (max-width: 780px) {
  .home-subTitle {
    margin-top: 15vh;
  }

  .home-subTitle-line {
    margin-top: 1vh;
  }

  .ant-avatar .ant-avatar-circle .ant-avatar-image {
    width: 70px !important;
    height: 70px !important;
  }

  .home-carousel-image-name {
    font-size: 11px;
    text-align: center;
  }

  .landing-footer__section {
    margin-left: 12px;
  }

  .home-carousel-image-view {
    font-size: 11px;
    text-align: right;
  }

  .homePage-structure {
  }

  .homePage-texts {
    font-size: 9px;
    margin-top: 6px;
  }

  .homePage-footer-logo {
    width: 20px;
  }

  .home-footer-copyText {
    margin-left: 30vw;
  }

  .homePage-logo {
    width: 150px;
  }

  .homePage-footer-titleList {
    font-size: 12px !important;
  }

  .home-footer-copyText {
    font-size: 10px !important;
  }
}
