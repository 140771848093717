.top-nav-controller {
  margin: 16px 0;
  @include flex(row, space-between, center);
  @include font(14, 700);
  width: 100%;
  color: #f0f0f0;

  div {
    cursor: pointer;
    user-select: none;
  }

  @include __element(previous) {
    @include flex(row, center, center);
    gap: 4px;
  }
}
