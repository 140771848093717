.info-container {
  width: 423px;
  height: fit-content;
  background: #d7e7ff;
  margin-top: 15px;
  border-radius: 4px;
  margin-bottom: 8px;
}
.error-container {
  width: 327px;
  height: fit-content;
  background: #ffccc7;
  margin-top: 15px;
  border-radius: 4px;
}
.info-row {
  display: grid;
}
.info-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin-left: 15px;
}
.error-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 20px;
}

.info-items-row {
  padding-inline: 20px;
  margin-top: 12px;

 }


@media screen and (max-width: 780px) {
  .info-container {
  width: 320px;
  }
}
@media screen and (max-width: 500px) {
  .info-container {
  width: 250px;
  }
}
