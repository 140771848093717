.tax-create-page {
  .red {
    color: #c04e4d;
  }

  .alert-message {
    width: 424px;
    margin: 16px auto 0;
  }

  @include __element(preview) {
    @include __element(spin) {
      text-align: center;
    }

    @include __element(buttons) {
      margin-top: 8px;
      @include flex(row, flex-end, center);
      gap: 8px;
    }
  }
}
.tax-create-error{
  color: #ff4d4f;

}

.tax-create-step-1 {
  @include flex(column, flex-start, flex-start);
  width: 100%;
  gap: 16px;
  max-height: 320px;
  overflow: auto;
}

.general-draft {
  margin-top: 64px;
}

.tax-create-step-2 {  
  @include __element(hint) {
    @include font(12, 400);
    color: #a4b3f4;

    &.red {
      color: #c04e4d;
    }
  }
}

.tax-create-step-3 {
  @include flex(row, center, center);
  gap: 8px;

  button {
    width: 100%;
    color: #ffff !important;

    &.green {
      background-color: #188a63;
      border-color: #188a63;
    }

    &.red {
      background-color: #c04e4d;
      border-color: #c04e4d;
    }

    &.disabled {
      background-color: gray !important;
      border-color: gray !important;
      color: #000 !important;
    }
  }
}
