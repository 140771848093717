.payment-reminder-page {
  @include __element(step-1) {
    @include __element(buttons) {
      @include flex(row, center, center);
      gap: 8px;

      button {
        width: 100%;
        color: #ffff !important;

        &.green {
          background-color: #188a63;
          border-color: #188a63;
        }

        &.red {
          background-color: #c04e4d;
          border-color: #c04e4d;
        }

        &.disabled {
          background-color: gray !important;
          border-color: gray !important;
          color: #000 !important;
        }
      }
    }

    @include __element(input) {
      margin-top: 16px;

      @include __element(header) {
        @include font(12, 400);
        color: #fff;

        span {
          &.red {
            color: #c04e4d;
            @include font(14,700);
          }
        }
      }
    }
  }

  @include __element(step-2) {
    @include flex(column, center, flex-start);
    width: 100%;

    @include __element(header) {
      width: 100%;
      text-align: right;
      user-select: none;
      color: #026bff;

      span {
        @include font(14, 500);
        cursor: pointer;
      }
    }

    @include __element(error) {
      color: #c04e4d;
      text-align: center;
    }

    @include __element(contacts) {
      margin-top: 16px;
      @include flex(column, center, center);
      width: 100%;
      gap: 8px;
    }
  }
}

.contact {
  @include flex(row, space-between, center);
  border: 1px solid #a4b3f4;
  width: 100%;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;

  @include __element(inputs) {
    @include flex(column, center, center);
    width: 100%;
    gap: 8px;
  }

  @include __element(buttons) {
    @include __element(remove) {
      @include flex(column, center, center);
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      user-select: none;
      width: 24px;
      height: 24px;
      background-color: #c04e4d;
    }
  }
}
