.header-icon {
  @include font(16, 400);
  @include flex(row, flex-start, center);
  cursor: pointer;
  user-select: none;
  gap: 8px;
  color: #fff;
  svg{
    fill, path{
      fill: #9CA3AF;
    }
  }
}
