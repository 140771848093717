.profile-page {
  @include __element(main-wrapper) {
    margin-top: 32px;
    min-width: 560px;
    max-width: 640px;
    width: 100%;
    @include flex(column, center, center);
    gap: 16px;

    @include __element(upload) {
    }

    @include __element(form) {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      row-gap: 16px;
      column-gap: 16px;

      @include __element(item) {
        width: 100%;
        @include flex(column, flex-start, flex-start);
        gap: 8px;

        .ant-select {
          width: 100%;
        }

        @include __element(header) {
          @include font(12, 400);
          color: #fff;
          span {
            @include font(12, 500);
            color: #de2d2d;
          }
        }
      }
    }
  }
}

.profile-loading {
  width: 100%;
  margin-top: 15px;
}
