.styled-button {
  border-radius: 4px;
  @include flex(row, center, center);

  svg {
    margin-right: 8px;
  }

  span {
    @include font(14, 700);
  }

  &.text-blue {
    span {
      color: #026bff !important;
    }
  }
  &:focus {
    span {
      color: #ffffff; /* White color */
    }
    .icon-svg {
      path {
        fill: #ffffff !important; /* White color */
      }
    }
  }
  &:hover {
    span {
      color: #ffffff; /* White color */
    }
    .icon-svg {
      path {
        fill: #ffffff !important; /* White color */
      }
    }
  }

  &.border-blue {
    border: solid 1px #026bff;
  }

  &.border-blue {
    border: solid 1px #026bff;
  }

  &.background-white {
    background-color: #fff;
  }
}

.styled-button-link {
  color: #026bff !important;
  @include font(14, 700);
  span {
    color: #026bff !important;
    @include font(14, 700);
  }
}

.styled-button-hover-white {
  &:hover {
    span {
      color: #ffffff !important; /* White color */
    }
    .icon-svg {
      path {
        fill: #ffffff !important; /* White color */
      }
    }
  }
}
