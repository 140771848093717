.resetPassword-form {
    box-sizing: border-box;
    width: 400px;
    height: 520px;
    background: linear-gradient(156.1deg, hsla(0, 0%, 100%, .28) -6.77%, hsla(0, 0%, 100%, .105) 30.71%);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(68px);
    border-radius: 8px;
    padding: 25px 35px 43px 35px;
    margin-top: 40px;
}

.resetPassword-form-input::-ms-input-placeholder {
    border: 1px solid;
    border-radius: 20px;
    background-color: #364453;
}

.resetPassword-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}

.resetPassword-form .ant-form-item-required::before {
    margin-left: 10px;
}

.resetPassword-form .ant-form-item-required::after {
    visibility: hidden;
    content: none;

}

.ant-row ant-form-item resetPassword-form-input {
    display: block;
}

.resetPassword-form-input {
    flex-direction: column;
}

.resetPassword-btn {
    align-items: center;
    padding: 12px 24px;
    gap: 10px;
    width: 100% !important;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
}

.resetPsw-header-text {
    align-self: center;
    margin-bottom: 15px;
    margin-left: 22px;
    width: 280px;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.reset-password-title{
    display: flex;
    justify-items: center;
    color: #FFFFFF;
    margin-left: 80px;
    font-size: 24px;
    font-weight: 700;

}

@media screen and (max-width: 780px) {
    .resetPsw-header-text {
        margin-left: 0;
        font-size: 12px;

    }
.reset-password-title{
    margin-left: 44px;

}
}
