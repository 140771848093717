.update-subscription-page {
  @include __element(step-1) {
    margin: 32px 0;
    @include __element(agreement) {
      > span {
        color: #fff;
        padding-left: 8px;
      }
    }

    @include __element(buttons) {
      margin-top: 32px;
      @include flex(column, center, center);
      gap: 16px;
      width: 100%;

      .styled-button {
        width: 100%;

        &.green {
          background-color: #03bc7d;
          border: 1px solid #03bc7d;
        }

        &.red {
          background-color: #c04e4d;
          border: #c04e4d 1px solid;
        }
      }
    }
  }

  @include __element(step-2) {
    margin: 32px 0;
    @include __element(header) {
      text-align: center;
      @include font(17, 700);
      color: #fff;
    }

    @include __element(rows) {
      margin: 32px 0;
      @include flex(column, flex-start, flex-start);
      flex-wrap: wrap;
      gap: 16px;
    }

    @include __element(agreement) {
      margin-top: 16px;
      > span {
        color: #fff;
        padding-left: 8px;
      }
    }

    .styled-button {
      width: 100%;
      margin-top: 32px;

      &.disable {
        span {
          color: #ccc;
        }
      }

      &.green {
        background-color: #03bc7d;
        border: #03bc7d 1px solid;
      }

      &.red {
        background-color: #c04e4d;
        border: #c04e4d 1px solid;
      }

      span {
        color: #fff;
      }
    }
  }
}

.row {
  @include flex(row, flex-start, center);
  gap: 16px;

  @include __element(title) {
    @include font(14, 500);
    color: #fff;

    &.deactive {
      color: #969696;
      text-decoration: line-through;
    }
  }

  @include __element(icon) {
    &.active {
      svg {
        path,
        fill {
          fill: #03bc7d;
        }
      }
    }

    &.deactive {
      svg {
        path,
        fill {
          fill: #969696;
        }
      }
    }
  }
}

.spin {
  text-align: center;
  width: 100%;
  margin: 16px 0;
}

.cancel-modal {
  @include flex(column, center, center);

  @include __element(header) {
    @include font(20, 600);
    text-align: center;
  }

  @include __element(body) {
    margin-top: 16px;
    @include font(17, 50);
    text-align: left;

    span {
      display: block;
    }
  }

  @include __element(buttons) {
    @include flex(column, center, center);
    margin-top: 32px;
    width: 100%;
    gap: 8px;

    .styled-button {
      width: 100%;

      &.red {
        color: #c04e4d;
        border: #c04e4d 1px solid;
      }
    }
  }
}

.hover-color-red {
  &:hover {
    color: #c04e4d !important;
    span {
      color: #c04e4d !important;
    }
  }
}
