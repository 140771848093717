.forgetPassword-title {
    display: flex;
    justify-items: center;
    color: #FFFFFF;
    margin-left: 65px;
    font-size: 24px;
    font-weight: 700;

}

.forgetPassword-close {
    margin-left: 320px;

}


.forgetPassword-header-text {
    align-self: center;
    margin-left: 106px;
    width: 280px;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-self: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;

}

@media screen and (max-width: 780px) and (min-width: 401px) {
    .resetPassword-form {
        width: 324px;

    }

    .forgetPassword-title {
        margin-left: 28px;

    }

    .forgetPassword-header-text{
        margin-left: 70px;

    }

    .forgetPassword-close {
        margin: 0 0 0 248px;
    }
}

@media screen and (max-width: 400px) {
    .resetPassword-form {
        width: 258px;

    }

    .forgetPassword-title {
        margin-left: 0;

    }
    .forgetPassword-header-text{
        margin-left: 30px;

    }
}
