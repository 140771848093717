.amount-default {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.amount {
    display: grid;
}

.Verify-lable {
    width: 304px;
    margin-top: 19px;
    margin-left: 30px;
    height: 40px;
    font-style: normal;
    font-weight: 50;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.amount-text {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    align-items: center;
    text-align: center;
}

/*.amount-formItem-input {*/
/*    padding: 24px 40px 30px 40px*/

/*}*/

.amount-form .ant-form-item-explain-error {
    color: #c04e4d;

}

.amount-form {
    width: 423px;
    height: 262px;
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 124.71%);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(185px);
    border-radius: 8px;
    opacity: 1.6;
}

.amount-form .ant-form-item-required::after {
    visibility: hidden;
    content: none;

}

.amount-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}

.amount-form .ant-form-item-required::before {
    margin-left: 8px;
}

.amount-formItem-btn {
    padding: 0;
}


.amount-form-line {
    border-top: 1px solid rgba(164, 179, 244, 1);
    opacity: 0.3;
}

.amount-form-btn {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 7px;
    position: absolute;
    width: 343px;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
}

.verify-resendCode {
    color: #026BFF;
    width: 100%;
}

@media screen and (max-width: 780px) and (min-width: 500px) {
    .Verify-lable {
        width: 262px;
        margin-top: 19px;
        margin-left: 3px;
    }
}

@media screen and (max-width: 499px) {
    .Verify-lable {
        width: 195px;
        margin: 0;
    }

    .verify-resendCode {
        margin-left: 30%;

    }

    .amount-default {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

    }
}
