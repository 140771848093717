$mobileSize: 425px;
$tabletSize: 768px;
$smallDesktopSize: 1440px;

@mixin __element($element) {
  &__#{$element} {
    @content;
  }
}

@mixin mobileStyle() {
  @media (max-width: $mobileSize) {
    @content;
  }
}

@mixin tabletStyle() {
  @media (max-width: $tabletSize) {
    @content;
  }
}

@mixin tabletStyle() {
  @media (max-width: $tabletSize) {
    @content;
  }
}

@mixin smallDesktopStyle() {
  @media (max-width: $smallDesktopSize) {
    @content;
  }
}

@mixin desktopStyle() {
  @media (min-width: $tabletSize) {
    @content;
  }
}

@mixin flex($flexDirection, $justifyContent: 0, $alignItems: 0) {
  display: flex;

  @if $flexDirection != 0 {
    flex-direction: $flexDirection;
  }

  @if $justifyContent != 0 {
    justify-content: $justifyContent;
  }

  @if $alignItems != 0 {
    align-items: $alignItems;
  }
}

@mixin font($fontSize, $fontWeight, $lineHeight: 0) {
  font-weight: $fontWeight !important;
  font-size: #{$fontSize}px !important;
  @if $lineHeight != 0 {
    line-height: $lineHeight;
  }
}

.init-app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #fff !important;
  border-color: #026bff !important;
  background: #026bff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.slick-dots {
  position: relative !important;
}

.placeholder-center {
  ::placeholder {
    text-align: center !important;
  }
}
