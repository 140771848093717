
.login-form {
    box-sizing: border-box;
    width: 400px;
    /*height: 550px;*/
    background: linear-gradient(156.1deg, hsla(0, 0%, 100%, .28) -6.77%, hsla(0, 0%, 100%, .105) 30.71%);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(68px);
    border-radius: 8px;
    padding: 25px 35px 43px 35px;
    margin-top: 40px;
}

.login-line {
    width: 35%;
    text-align: center;
    border-bottom: 1px solid #ffffff;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.login-line span {
    background: #fff;
    padding: 0 10px;
}

.login-form .ant-form-item-required::before {
    margin-left: 10px;
}

.login-header-text {
    align-self: center;
    margin-bottom: 15px;
    margin-left: 22px;
    width: 280px;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.login-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}


.container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.login-btn {
    align-items: center;
    padding: 12px 24px;
    gap: 10px;
    width: 100% !important;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
}

.login-form-input::-ms-input-placeholder {
    border: 1px solid;
    border-radius: 20px;
    background-color: #364453;
}

.login-footer-text {
    width: 153px;
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.login-form-title {
    display: flex;
    justify-items: center;
    color: #FFFFFF;
    margin-left: 110px;
    font-size: 29px;
}

.login-form-countinue {
    color: #FFFFFF;
    width: 110px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
    margin-left: 5px;
}

@media screen and (max-width: 412px) {
    .login-form {
        width: 265px;
    }

    .login-form-title {
        margin-left: 62px;

    }

    .login-header-text {
        width: 197px;
        margin-left: 0;
    }

    .login-form-countinue {
        width: 172px;

    }

}


/*.login {*/
/*    position: absolute;*/
/*    width: 650px;*/
/*    height: 650px;*/
/*    left: 25%;*/
/*    top: 200px;*/
/*    background: #364453;*/
/*}*/

/*.login-row {*/
/*    position: absolute;*/
/*    width: 73px;*/
/*    height: 42px;*/
/*    top: 35px;*/
/*    left: calc(50% - 73px / 2 - 4.5px);*/
/*    color: white;*/
/*    font-family: 'Lato';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 30px;*/
/*    line-height: 42px;*/
/*}*/

/*.input-email {*/

/*    position: absolute;*/
/*    width: 370px;*/
/*    height: 61px;*/
/*    left: 146px;*/
/*    top: 128.63px;*/
/*    background-color: #364453;*/
/*    border: 2px solid #DADADA;*/
/*    box-sizing: border-box;*/
/*    border-radius: 10px;*/
/*}*/

/*.input-email::placeholder {*/
/*    position: absolute;*/
/*    top: 15px;*/
/*    font-family: 'Lato';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 22px;*/
/*    line-height: 24px;*/
/*    display: flex;*/
/*    align-items: center;*/

/*    color: rgba(255, 255, 255, 0.5);*/
/*}*/

/*.ant-input::placeholder {*/
/*    position: absolute;*/
/*    top: 15px;*/
/*    font-family: 'Lato';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 22px;*/
/*    line-height: 24px;*/
/*    display: flex;*/
/*    align-items: center;*/

/*    color: rgba(255, 255, 255, 0.5);*/
/*}*/

/*.input-password {*/
/*    position: absolute;*/
/*    width: 370px;*/
/*    height: 61px;*/
/*    left: 146px;*/
/*    top: 210px;*/
/*    border: 2px solid #DADADA;*/

/*    background-color: #364453;*/
/*    box-sizing: border-box;*/
/*    border-radius: 10px;*/
/*}*/

/*.text-forget {*/
/*    position: absolute;*/
/*    width: 315px;*/
/*    height: 20px;*/
/*    left: 370px;*/
/*    top: 280.66px;*/

/*    font-family: 'Lato';*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 16px;*/
/*    line-height: 20px;*/

/*    display: flex;*/
/*    align-items: center;*/
/*    text-align: right;*/

/*    color: #FFFFFF;*/
/*}*/

/*.login-btn {*/
/*    position: absolute;*/
/*    max-width: 370px;*/
/*    height: 61px;*/
/*    left: 146px;*/
/*    top: 550.87px;*/
/*    background: #026BFF;*/
/*    border-radius: 12px*/
/*}*/

/*.login-checkbox {*/
/*    position: static;*/
/*    width: 132px;*/
/*    height: 25px;*/


/*    font-family: 'Lato';*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 15px;*/
/*    line-height: 140%;*/
/*    color: #FFFFFF;*/
/*    flex: none;*/
/*    order: 1;*/
/*    flex-grow: 1;*/
/*    margin: 350px 170px;*/
/*}*/

/*.login-icons-google {*/
/*    position: absolute;*/
/*    width: 48px;*/
/*    height: 48px;*/
/*    right: 300px;*/
/*    top: 477px;*/
/*    background: rgba(255, 255, 255, 0.06);*/
/*    box-shadow: 0px 11px 25px -5px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 72px;*/
/*}*/

/*.login-icons-facebook {*/
/*    position: absolute;*/
/*    width: 48px;*/
/*    height: 48px;*/
/*    right: 230px;*/
/*    top: 477px;*/
/*    background: rgba(255, 255, 255, 0.06);*/
/*    box-shadow: 0px 11px 25px -5px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 72px;*/
/*}*/

/*.login-icons-apple {*/
/*    position: absolute;*/
/*    width: 48px;*/
/*    height: 48px;*/
/*    right: 160px;*/
/*    top: 477px;*/
/*    background: rgba(255, 255, 255, 0.06);*/
/*    box-shadow: 0px 11px 25px -5px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 72px;*/
/*}*/

/*.wrapper-password .ant-input-password .ant-input {*/
/*    background-color: #364453;*/
/*}*/
