.form-radio {
  border-radius: 4px;
  border: 1px solid #ececec;
  background: #fff;
  @include flex(row, flex-start, center);
  cursor: pointer;
  user-select: none;
  width: 100%;
  padding: 8px;

  @include __element(styled-radio){
    margin-right: 32px;
  }

  span {
    @include font(14, 500);
    color: #969696;
  }
}
