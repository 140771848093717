.header-cmp {  
  padding-top: 24px;
  width: 100%;
  @include flex(column, flex-start, flex-start);

  @include __element(title) {
    @include font(24, 700);
    color: #fff;
  }

  @include __element(divider) {
    height: 1px;
    background-color: #a4b3f4;
    opacity: 0.3;
    width: 100%;
    margin: 24px 0;
  }
}
