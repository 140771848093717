.leave-review-page {
  @include __element(main-wrapper) {
    width: 100%;
    padding: 24px 0;  
    height: 290px;
    min-width: 80vw;
  
    @include flex(row, space-between, flex-start);
    gap: 64px;

    @include __element(rate-section) {
      min-width: 224px;
      span {
        @include font(12, 400);
        color: #fff;
      }

      @include __element(divider) {
        height: 1px;
        width: 100%;
        background-color: #a4b3f4;
        margin: 8px 0 0;
      }
    }

    @include __element(comment-section) {
      width: 100%;
      height: 100%;
      @include flex(column, space-between, flex-start);
      @include __element(header) {
        @include font(12, 400);
        color: #fff;
        margin-bottom: 4px;
        span {
          color: #c04e4d;
        }
      }

      textarea {
        height: 100%;
      }

      @include __element(footer) {
        @include font(12, 400);
        color: #a4b3f4;
        margin-top: 4px;
        text-align: right;
        width: 100%;
      }
    }
  }

  @include __element(section-rate) {
    margin: 8px 0;
    @include flex(row, space-between, center);
    @include __element(title) {
      @include font(12, 400);
      color: #fff;
    }

    @include __element(star-wrapper) {
      ul {
        margin-top: 4px;
        li {
          float: left;
          cursor: pointer;
          @include flex(column, center, center);
          height: 100%;
        }
      }
    }
  }
}
