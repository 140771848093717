@font-face {
  font-family: "Inter";
  src: url("src/assets/font/Inter-Regular.ttf");
}

.note-form-box {
  width: 423px;
  height: 100%;
  background: linear-gradient(
    156.1deg,
    rgba(255, 255, 255, 0.28) -6.77%,
    rgba(255, 255, 255, 0.105) 0.71%
  );
  box-shadow: 0px 4px 16px rgb(255 255 255/6%);
  -webkit-backdrop-filter: blur(185px);
  backdrop-filter: blur(185px);
  border-radius: 8px;
  opacity: 1.6;
  border: 1px solid rgba(18, 54, 110, 0.6);
  position: relative;
  padding: 30px;
}

.ant-select-selection-placeholder {
  text-align: left;
}

.ant-row .ant-form-item-row {
  display: flex;
  flex-direction: column;
}

/* .note-form-box .note-form-btn */
/* .note-form-box .note-form-btn {
    width: 50%;
}
.note-form-box .note-title,
.note-form-box .note-formItem-input {
  padding: 30px;
} */

.credit-side-form-box {
  width: 750px !important;
  max-height: 506px !important;
  background: linear-gradient(
    156.1deg,
    rgba(255, 255, 255, 0.28) -6.77%,
    rgba(255, 255, 255, 0.105) 0.71%
  );
  box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(185px);
  border-radius: 8px;
  opacity: 1.6;
  border: 1px solid rgba(18, 54, 110, 0.6);
  position: relative;
  padding: 17px 17px 17px 43px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.credit-side-form-box::-webkit-scrollbar {
  width: 2em;
}

.credit-side-form-item-header {
  text-align: left;
  margin-left: 14px;
}

.credit-side-form-input-container {
  margin-top: 5px;
}

.agreement-text {
  color: #ffffff;
  font-size: 25px;
  margin-top: 25px;
}

.note-add-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: right;

  color: #026bff;
  padding: 10px;
}

.note-add-content:hover {
  cursor: pointer;
}

.note-remove-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: right;
  cursor: pointer;
  color: #d71732;
  padding: 10px;
}

.note-title {
  font-style: normal;
  font-family: "Inter";
  font-weight: 700;
  font-size: 19px;
  line-height: 32px;
  align-items: center;
  text-align: center;
}

.note-default {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  margin-top: 10px;
}

.note {
  display: grid;
}

.row-input {
  padding: 9px 24px;
  /*gap: 10px;*/
  width: 200px;
  height: 44px;
  margin-right: 5px;
  text-align: center;
}

.row-input::placeholder {
  color: black;
  text-align: center;
}

.note-btn-yes {
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 10px;
  width: 164px;
  height: 44px;
  background: #188a63;
  border: none;
  border-radius: 4px;
  margin-right: 27px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.note-btn-yes:hover {
  background: #188a63;
  color: white;
}

.note-btn-yes:focus {
  background: #188a63;
  color: white;
}

.note-btn-yes:active {
  background: #188a63;
  color: white;
}

.note-btn-no:active {
  background: #c04e4d;
  color: white;
}

.note-btn-no:hover {
  background: #c04e4d;
  color: white;
}

.note-btn-no:focus {
  background: #c04e4d;
  color: white;
}

.note-btn-no {
  text-align: center;
  font-weight: bold;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 10px;
  width: 164px;
  height: 44px;
  background: #c04e4d;
  border: none;
  border-radius: 4px;
}

.note-form-box .ant-form-item-explain-error {
  color: #ff4d4f;
  text-align: left;
}

.note-btn-enable {
  background-color: gray !important;
  color: black !important;
}

.note-btn-enable:hover {
  background-color: gray;
  color: black;
}

.note-secondery-btn-yes {
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 10px;
  width: 164px;
  height: 44px;
  background: black;
  border: none;
  border-radius: 4px;
  margin-right: 27px;
  color: white;
  font-weight: bold;
  text-align: center;
  color: white;
  padding-bottom: 20px;
}

.note-secondery-btn-no {
  text-align: center;
  font-weight: bold;
  color: black;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 10px;
  width: 164px;
  height: 44px;
  background: white;
  border: none;
  border-radius: 4px;
}

.note-formItem-input {
  /*   padding: 0px 40px 0px 40px;
     */
  width: 100%;
  margin-bottom: 4px;
  margin: auto;
  text-align: center;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.note-formItem-input::placeholder {
  text-align: left;
}

.ant-input-affix-wrapper > input.ant-input {
  text-align: center;
}

.ant-input-affix-wrapper > input.ant-input::placeholder {
  text-align: left;
}

.ant-form-item-label > label {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: white;
}

.ant-form-item-label > label:after {
  content: none;
}

.note-form-box .ant-form-item-required::after {
  visibility: hidden;
  content: none;
}

.note-form-box .ant-form-item-required {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  color: white;
}

.note-form-box .ant-form-item-required::before {
  margin-left: 8px;
}

.note-form-line {
  border-bottom: 1px solid rgba(164, 179, 244, 1);
  opacity: 0.3;
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 20px;
}

.note-form-btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 7px;
  /*   position: absolute;
     */
  width: 100%;

  height: 44px;
  background: #026bff;
  border-radius: 4px;
  margin-top: 50px;
}
.error-box{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 7px;
  /*   position: absolute;
     */
  width: 100%;

  height: 44px;
  /* background: #026bff; */
  /* border-radius: 4px; */
  margin-top: 50px;

}
.error-container{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 7px;
 
  width: 100%;

  height: 44px;
   /* border-radius: 4px; */
  /* margin-top: 50px; */
}
.note-formItem-input-date {
  width: 360px;
  border-radius: 5px;
  height: 38px;
}

.note-btn-group {
  background-color: white;
  color: black;
  height: 35px;
  border: none;
  border-left: 1px solid black;
}

.note-btn-group:hover {
  background-color: white;
  color: black;
  border: none;
  border-left: 1px solid black;
}

.label-wrap .ant-form-item-label > label {
  height: 76px;
  white-space: normal;
  text-align: left;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.radio-container {
  background-color: white;
  height: 40px !important;
  padding-inline: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.btn-lable span {
  margin-top: 3px;
}

.radio-container span.ant-radio + * {
  padding-left: 45px;
  /*   color: #969696;
   */
}

.note-max {
  width: 128px;
  height: 16px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  /* Sub content */
  color: #a4b3f4;

  float: right;
  padding-top: 6.9px;
}

.note-demandPaymenct {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ant-btn .ant-btn-default .note-secondery-btn-yes:after {
  color: #1890ff;
}

.ant-btn .ant-btn-default .note-secondery-btn-yes:hover {
  color: #1890ff;
}

.note-secondery-btn-yes:hover {
  background-color: black;
  color: #1890ff;
}

.note-secondery-btn-yes:after {
  background-color: black;
  color: #1890ff;
}

.note-title-collateral {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 20px;
  text-align: center;
  margin-left: -26px;
}

.agreement-input-date-inp::placeholder {
  text-align: left;
}

.agreement-input-date-inp {
  width: 343px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
}

.note-secondery-btn-yes:active {
  background-color: black;
  color: #1890ff;
}

.note-secondery-btn-yes:focus {
  background-color: black;
  color: #1890ff;
}

@media screen and (max-width: 780px) {
  .credit-side-form-box {
    width: 90vw !important;
  }

  .label-wrap .ant-form-item-label > label {
    height: 145px;
  }

  .note-demandPaymenct {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .note-secondery-btn-yes {
    margin: 0 0 10px 0px;
  }

  .lump-input-date-inp {
    width: 256px;
  }

  .agreement-form {
    width: 270px;
    margin: 0;
  }

  .agreement-form-btn {
    width: 317px;
  }
}

@media screen and (max-width: 780px) {
  .note-default-previous {
    /* margin-right: 70px; */
  }

  .align-right {
    justify-content: unset !important;
  }

  .agreement-input-date-inp {
    width: 212px;
  }

  .agreement-text {
    font-size: 14px;
    text-align: end;
  }
  .note-add-content {
    margin-right: 50px;
  }

  .note-form-box {
    width: 320px;
    /* margin: 0 37px; */
  }

  .credit-side-form-item-header {
    margin-left: 0;
  }

  .note-title-collateral {
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .note-form-box {
    width: 250px;
    margin: 0 37px;
  }
}

@media screen and (max-width: 500px) {
  .row-input {
    width: 190px;
  }
}

.radio-container .ant-radio-wrapper {
  width: 100%;
}

.radio-container .ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
}

.ant-radio-inner {
  border: 3px solid #55616e;
}

@media screen and (max-width: 580px) and (min-width: 500px) {
  .row-input {
    width: 260px;
  }
}
