.register-form {
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 0.71%);
    border-radius: 8px;
    backdrop-filter: blur(68px);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    padding-top: 5px;
    padding-bottom: 7px;
}

.register-title-txt{
    width: 406px;
    margin-top: 19%;
    align-self: center;
    margin-left: 20%

}

.register-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}

.register-form .ant-form-item-required::before {
    margin-left: 10px;
}

.register-input {
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
}

.register-btn {
    border: 1px solid #026bff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;
    width: 77%;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
    margin-top: 11px;
    margin-left: 50px;
    color: white;
}

.register-btn:after {
    background-color: white;
    color: white;
}

.register-btn:hover {
    background-color: #026BFF;
    color: white;

}

.register-text {
    display: flex;
    flex-direction: row;
}

.register-form-line {
    border-bottom: 1px #A4B3F4 solid;
    margin-top: 11px;

}

.register-already-text{
    /*margin-left: 102px;*/
    margin-top: 2px;

}

.register-form-input {
    margin: 0 0 2px;
    padding: 0 50px 0 50px;
}

.ant-checkbox + span {
    color: #FFFFFF;
}
@media screen and (max-width: 780px) and (min-width: 768px)  {
    .register-title-txt{
     margin-left: 0;
    }
    /*.register-already-text{*/
    /*    margin-left: 70px;*/
    /*}*/
}
/*@media screen and (max-width: 768px){*/
/*    .register-already-text{*/
/*        margin-left: 236px;*/
/*    }*/

/*}*/
@media screen and (max-width: 780px) {
    .register-title-txt{
     margin-left: 5%;
    }
    /*.register-already-text{*/
    /*    margin-left: 70px;*/
    /*}*/
}
