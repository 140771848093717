.interest-btn-yes {
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    gap: 10px;
    width: 164px;
    height: 44px;
    background: #188A63;
    border: 1px solid #188A63;
    border-radius: 4px;
    margin-right: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
}

.interest-btn-yes:hover {
    background: #188A63;
    border: 1px solid #188A63;
    color: white;
}

/*.interest-btn-yes:focus:hover {*/
/*    background-color: white;*/
/*    border: 1px solid #40a9ff;*/
/*    color: #40a9ff;*/
/*}*/

.interest-btn-no:focus:hover {
    /*background-color: white;*/
    /*border: 1px solid #40a9ff;*/
    background: #C04E4D;
    border: 1px solid #C04E4D;
    color: #FFFFFF;
}

.ant-btn:active .ant-btn:focus .ant-btn:hover{
    background: #C04E4D;
    border: 1px solid #C04E4D;
    color: #FFFFFF;

}

.ant-btn:focus .ant-btn:hover{
    background: #C04E4D;
    border: 1px solid #C04E4D;
    color: #FFFFFF;


}

.interest-btn-no {
    text-align: center;
    font-weight: bold;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    gap: 10px;
    width: 164px;
    height: 44px;
    background: #C04E4D;
    border: 1px solid #C04E4D;
    border-radius: 4px;
}

.interest-btn-no:hover {
    background: #C04E4D;
    border: 1px solid #C04E4D;
    color: white;
}

.interest-form {
    width: 423px;
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 124.71%);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    -webkit-backdrop-filter: blur(68px);
    backdrop-filter: blur(68px);
    border-radius: 8px;
    padding: 17px 30px 30px 40px;
}

.interest {
    display: grid;
    margin-top: 0;
}

.interest-formItem-input {
    padding: 0;

}

.interest-form {
    width: 423px;
    height: 380px;
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 124.71%);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    -webkit-backdrop-filter: blur(68px);
    backdrop-filter: blur(68px);
    border-radius: 8px;
}

.interest-text {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    align-items: center;
    text-align: center;
}

.amount-formItem-input {

}

.interest-form .ant-form-item-required::after {
    visibility: hidden;
    content: none;

}

.interest-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}

.interest-form .ant-form-item-required::before {
    margin-left: 8px;
}

.interest-formItem-btn {
    padding: 0 40px 0 40px;

}

.interest-form .ant-form-item-explain-error {
    color: #c04e4d;
}

.interest-form-line {
    margin-top: 15px;
    border-top: 1px solid rgba(164, 179, 244, 1);
    opacity: 0.3;
}

.interest-form-btn {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    width: 343px;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
}
