.link-box {
  border-radius: 4px;
  border: 1px solid rgba(164, 179, 244, 0.3);
  background-color: #202848;
  padding: 8px 16px;
  user-select: none;
  cursor: pointer;
  @include flex(row, space-between, center);

  @include __element(left) {
    @include flex(row, flex-start, center);
    gap: 8px;
    overflow: hidden;
    width: 100%;

    @include __element(title) {
      @include font(14, 400);
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 128px;
      white-space: nowrap;

      @include desktopStyle {
        overflow: auto;
        max-width: 100%;
      }

      @include tabletStyle {
        width: 100%;
        white-space: normal;
      }
    }

    @include __element(premium) {
      border-radius: 2px;
      background: #03bc7d;
      color: #fff;
      padding: 0 8px;
      @include font(14, 400);
    }
  }

  @include __element(right) {
    padding-top: 8px;
    flex-direction: row;
    display: flex;
  }
}
