.alert-message {
  border-radius: 4px;
  border: 1px solid #69c0ff;
  @include flex(row, flex-start, flex-start);
  user-select: none;
  padding: 8px 16px;
  gap: 8px;

  @include __element(icon) {
    min-width: 16px;
    min-height: 16px;
  }

  @include __element(message) {
    @include font(14, 400);
    color: #000000d9;
  }
}
