.user-pages-navbar {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  height: 64px;
  background-color: #202848;

  @include tabletStyle {
    top: 24px;
  }

  @include __element(main-wrapper) {
    margin: 0 auto;
    @include flex(row, center, center);
    height: 100%;
    gap: 16px;

    @include tabletStyle {
      margin: auto;
      gap: 8px;
    }

    @include mobileStyle {
      gap: 2px;
    }

    @include __element(btn) {
      @include font(14, 500);
      cursor: pointer;
      user-select: none;
      border-radius: 8px;
      color: #ffffff;
      padding: 8px;

      @include mobileStyle {
        @include font(11, 500);
      }

      &.selected {
        background-color: #343e66;
        cursor: auto;
        border: none;
      }
    }
  }
}
