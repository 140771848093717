.amount-default {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.amount {
    display: grid;
}

.amount-text {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    align-items: center;
    text-align: center;
}

.amount-formItem-input {
    padding: 24px 40px 0 40px

}

.amount-form {
    width: 423px;
    height: 262px;
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 0.71%);
    box-shadow: 0px 4px 16px rgb(255 255 255 / 6%);
    -webkit-backdrop-filter: blur(185px);
    backdrop-filter: blur(185px);
    border-radius: 8px;
    opacity: 1.6;
    border: 1px solid rgba(70, 144, 255, 0.6);


}

.amount-form .ant-form-item-required::after {
    visibility: hidden;
    content: none;

}

.amount-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}

.amount-form .ant-form-item-required::before {
    margin-left: 8px;
}

.amount-formItem-btn {
    padding: 25px 0 0 0;

}


.amount-form .ant-form-item-explain-error {
    color: #c04e4d;
}

.amount-form-line {
    border-top: 1px solid rgba(164, 179, 244, 1);
    opacity: 0.3;
    margin: 30px 0 35px 0;
}

.amount-form .ant-form-item-explain-error{
    color: #c04e4d;

}

.amount-form-btn {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 7px;
    position: absolute;
    width: 343px;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
}
