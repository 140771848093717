.cancel-note-generation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cancel-note-generation__header {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}

.cancel-note-generation__note {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }

.cancel-note-generation__button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.cancel-note-generation__button-wrapper .ant-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border-radius: 8px;
}

.cancel-note-generation__button-wrapper .ant-btn.ant-btn-text{
  color: #D32F2F;
}
