.amortization-schedule-page {
  @include __element(preview) {
    @include __element(spin) {
      text-align: center;
    }

    @include __element(content) {
      @include flex(column, flex-start, flex-start);

      @include __element(info) {
        @include flex(column, flex-start, flex-start);
        gap: 16px;

        @include __element(row) {
          @include flex(row, flex-start, center);

          @include __element(key) {
            @include font(14, 400);
            color: #a4b3f4;
            width: 120px;
          }

          @include __element(value) {
            @include font(14, 700);
            color: #fff;
          }
        }
      }
    }

    @include __element(buttons) {
      margin-top: 16px;
      @include flex(row, flex-end, center);
      gap: 8px;
    }
  }
}

.amortization-table {
  width: 100%;
  max-height: 50vh;
  height: 50vh;
  overflow: auto;  
  border: none;
  margin-top: 24px;

  table{
    width: 100%;
    
    thead {
      height: 48px;
  
      tr {
        background: #a4b3f4;
        box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(34px);
        th {
          @include font(14, 500);
          color: #fff;
          text-align: center;
        }
      }
    }
  
    tbody {
      tr {
        height: 48px;
        background-color: transparent;
        border: none;
        td {
          @include font(14, 400);
          text-align: center;
          color: #fff;
          border: none;
        }
  
        &:nth-of-type(even) {
          background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.16) 0%,
            rgba(255, 255, 255, 0.06) 100%
          );
          box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.06);
          backdrop-filter: blur(34px);
        }      
      }
    }
  }
}
