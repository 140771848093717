
#content {
    display: none;
}

#thumbnails {
    display: none;
}

#iframe-container #sidenav-container {
    display: none !important;
}

#container #sidenav-container {
    display: none !important;

}

.draft-form {
    overflow-y: scroll;
    width: 40vw;
    height: 595px;
    background: #202848;
    padding: 0 20px 8px 20px;
    background: linear-gradient(
            156.1deg,
            rgba(255, 255, 255, 0.28) -6.77%,
            rgba(255, 255, 255, 0.105) 0.71%
    );
    box-shadow: 0px 4px 16px rgb(255 255 255/6%);
    -webkit-backdrop-filter: blur(185px);
    border-radius: 8px;
    opacity: 1.6;
}

.amount {
    display: grid;
}

.amount-text {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    align-items: center;
    text-align: center;
}

.amount-formItem-input {
    padding: 24px 40px 0 40px

}

.amount-form {
    width: 423px;
    height: 262px;
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 0.71%);
    box-shadow: 0px 4px 16px rgb(255 255 255 / 6%);
    -webkit-backdrop-filter: blur(185px);
    backdrop-filter: blur(185px);
    border-radius: 8px;
    opacity: 1.6;
    border: 1px solid rgba(70, 144, 255, 0.6);


}

.amount-form .ant-form-item-required::after {
    visibility: hidden;
    content: none;

}

.amount-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}

.amount-form .ant-form-item-required::before {
    margin-left: 8px;
}

.amount-form .ant-form-item-explain-error {
    color: #c04e4d;
}

.amount-form-line {
    border-top: 1px solid rgba(164, 179, 244, 1);
    opacity: 0.3;
    margin: 30px 0 35px 0;
}

.amount-form .ant-form-item-explain-error {
    color: #c04e4d;

}

.amount-form-btn {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 7px;
    position: absolute;
    width: 343px;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
}

.draft-btns {
    display: flex;
    flex-direction: row-reverse;
    margin: 20px 0;
}

.draft-btns-center {
    background: #188A63;
    border-radius: 4px;
    border: 1px solid #026BFF;
    color: white;
    margin: 0 15px;
    font-weight: 500;
}

.draft-btns-center:hover {
    background: #188A63;
    border: 1px solid #026BFF;
    color: white;
}

@media screen and (max-width: 780px) {
    .draft-btns {
        flex-direction: column;
        align-items: center;
    }

    .draft-btns-center {
        margin: 10px 0;

    }

    .landing-carts {
        flex-direction: column;
    }

    .landing-carts-second-cart {
        margin: 30px 0;
    }
}

@media screen and (max-width: 960px) {
    .landing-carts {
        flex-direction: column;
        justify-content: space-between;
    }


}

