.resetPassword-btn {
  padding: 12px 24px;
  gap: 10px;
  width: 194px;
  height: 48px;
  background: #026bff;
  border-radius: 4px;
}

.landing-text {
  width: 488px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.Landing-head-btn span {
  text-align: center;
}

.landing-help-box-First-text {
  font-size: 2.1rem;
}

.landing-help-box-Second-text {
  font-size: 2.1rem;
}

.ant-btn.ant-btn-block {
  width: 160px;
}

.landing-head-cart {
  width: 100%;
  height: 190px;
  background: linear-gradient(
    156.1deg,
    rgba(255, 255, 255, 0.28) -79.77%,
    rgba(255, 255, 255, 0.105) 53.71%
  );
  box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(68px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 15px;
}

.landing-head-cart-center {
  width: 100%;
  height: 190px;
  background: linear-gradient(
    156.1deg,
    rgba(255, 255, 255, 0.28) -79.77%,
    rgba(255, 255, 255, 0.105) 53.71%
  );
  box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(68px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 15px;
}

.landing-head-cart-text {
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.landing-head-cart-title {
  width: 100%;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.landing-carts-list-texts {
  margin-left: 10px;
}

.landing-head-cart-btn {
  align-items: center;
  padding: 0 16px;
  gap: 10px;
  width: 108px;
  height: 32px;
  background: #026bff;
  border-radius: 4px;
  border: 1px;
  color: #ffffff;
}

.landing-center {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.landing-center-imgs {
  margin-right: 20vw;
  margin-top: 15px;
}

.landing-image-btn {
  padding: 12px 48px 16px 48px;
  gap: 10px;
  color: #ffffff;
  border: 1px;
  width: 219px;
  height: 48px;
  background: #00be9f;
  border-radius: 8px;
  margin-left: 0;
}

.landing-page-center-fram-account2 {
  position: absolute;
  top: 300px;
  margin-left: 6vw;
}

.landing-page-img-account {
  position: absolute;
  left: 297px;
  top: 120px;
}

.landing-page-fram-box {
  position: absolute;
  top: 147px;
}

.landing-carts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 60px;
}

.landing--carts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 60px;
}

.landing-carts-first-cart {
  width: 305px;
  height: 512px;
  background: #202848;
  border-radius: 16px;
  padding-top: 15px;
}

.landing-carts-first-cart- {
  width: 305px;
  height: 512px;
  background: #202848;
  border-radius: 16px;
  padding-top: 15px;
}

.landing-carts-second-cart {
  margin: 0 22px;
  border-radius: 16px;
  width: 305px;
  height: 512px;
  background: #026bff;
  padding-top: 15px;
  position: relative;
}

.landing-carts-second-cart- {
  margin: -12px 22px 0 22px;
  border-radius: 16px;
  width: 305px;
  height: 512px;
  background: #026bff;
  padding-top: 15px;
  position: relative;
}

.landing-carts-second-cart-label {
  width: 142px;
  height: 28px;
  background: #188a63;
  border-radius: 171px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: -12px;
  left: 10px;
}

.landing-carts-first-cart-line {
  border-bottom: 1px solid #a4b3f4;
  /*width: 310px;*/
  height: 0px;
  padding-top: 15px;
}

/* d*/

.landing-help-box {
  /*background-image: url("../../assets/images/landing-page-footer-box.png");*/
  width: 100%;
  /*height: 260px;*/
  /*background: #202848;*/
  /*box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);*/
  /*border-radius: 16px;*/
  margin-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*padding-top: 18px;*/
  /*margin-bottom: 26vh;*/
  padding: 25px 10px;
  position: absolute;
  bottom: 17px;
}

.landing-page-footer-divBox {
  position: relative;
  width: 73vw;
  margin: 50px auto auto auto;
  height: 300px;
}

.landing-help-box-Second-text {
  height: 96px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #a4b3f4;
}

.landing-carts-price {
  color: #ffffff;
  text-align: center;
}

.landing-carts-price- {
  color: #ffffff;
  text-align: center;
  font-size: 40px;
}

.landing-carts-center-btn {
  padding: 12px 24px;
  gap: 10px;
  position: absolute;
  width: 240px;
  height: 44px;
  background: white;
  color: #026bff;
  border: 1px;
  font-weight: bold;
  border-radius: 4px;
  margin: 47px 0 0 34px;
}

.landing-footer_section {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.landing-footer__section__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-end;
  gap: 40px;
}

.landing-footer__section__col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: "100%";
  justify-content: center;
}

.select-carts-center-btn {
  padding: 12px 24px;
  gap: 10px;
  position: absolute;
  width: 240px;
  height: 44px;
  background: white;
  color: #026bff;
  border: 1px;
  font-weight: bold;
  border-radius: 4px;
  margin: 16px 0 0 34px;
}

.landing-page-box-images {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
}

.landing-page-head-bts {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
}

.landing-carts-btn {
  padding: 12px 24px;
  gap: 10px;
  position: absolute;
  width: 240px;
  height: 44px;
  background: #026bff;
  color: #ffffff;
  border: 1px;
  font-weight: bold;
  border-radius: 4px;
  margin: 47px 0 0 34px;
}

.select-carts-btn {
  padding: 12px 24px;
  gap: 10px;
  position: absolute;
  width: 240px;
  height: 44px;
  background: #026bff;
  color: #ffffff;
  border: 1px;
  font-weight: bold;
  border-radius: 4px;
  margin: 16px 0 0 34px;
}

.landing-help-box-First-text {
  text-align: center;
  color: #ffffff;
}

.landing-footer--imageTxt- {
  text-align: center;
  color: #ffffff;
  margin-left: 80px;
}

.landing-help-box-btn {
  padding: 12px 48px;
  gap: 10px;
  width: 171px;
  height: 44px;
  background: #026bff;
  border-radius: 4px;
  border: 1px;
  color: #ffffff;
  margin-bottom: 14px;
}

.landing-page-text-end1 {
  font-family: Inter;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: #23c693;
}

.landing-page-text-end2 {
  color: white;
  font-family: Inter;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 15px;
}

.landing-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 160px;
  padding-bottom: 60px;
  justify-content: space-between;
}

.landing-footer-list-loanthem {
  width: 100%;
  display: block;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #f0f0f0;
  white-space: nowrap;
}

/*.ant-typography h1, div.ant-typography-h1, div.ant-typography-h1 > textarea, h1.ant-typography {*/
/*    width: 500px;*/
/*}*/

.landing-section--carts {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  gap: 8px;
  margin-bottom: 15px;
}

.landing-title--dec {
  margin-top: 50px;
}

.landing-head-cart-title {
  width: 100%;
  height: 24px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  font-style: normal;
}

.landing-page-img-center-fram {
  margin-bottom: 115px;
  width: 75%;
  margin-top: 10vh;
}

.landing-page-background-img {
  height: 590px;
  align-self: center;
}

.landing-page-header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.landing-page-iphone12 {
  height: 450px;
}

.landing-page-background-center-fram {
  background-image: url("../../assets/images/landing-page-image-footer.png");
  width: 101vw;
  transform: translateX(-7vw);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  /* background-position: bottom; */
  background-repeat: no-repeat;
  background-size: cover;
}
.store_links img {
  width: 45%;
}

@media screen and (max-width: 1185px) {
  .store_links img {
    width: 120px;
  }
  .landing-page-background-img {
    height: 430px;
    align-self: center;
  }

  .landing-page-img-center-fram {
    margin-left: 0;
  }

  .landing-page-header-txt-btn {
  }
}

@media screen and (max-width: 1070px) {
  .landing-page-background-center-fram {
    transform: translateX(-7vw);
  }

  .landing-page-background-img {
    width: 70vw;
    align-self: center;
  }

  .landing-page-img-center-fram {
    margin-bottom: 20px;
    height: 380px;
    width: 90%;
  }

  .landing-page-header-section {
    flex-direction: column-reverse;
  }

  .landing-center {
    margin-right: 0;
  }

  .landing-center-imgs {
    display: flex;
    margin-right: 0;
  }

  .landing-section--carts {
    margin-top: 48px;
    justify-content: space-between;
  }

  .landing-head-cart-title {
    width: 100%;
    height: 35px;
  }

  .landing-head-cart-btn {
    margin-top: 20px;
  }

  .landing-center {
    margin-top: 30px;
    align-items: center;
  }

  .landing-carts-second-cart- {
    width: 230px;
    margin: 0 4px;
    height: 458px;
  }

  .landing-carts-first-cart- {
    width: 230px;
    height: 458px;
  }

  .landing-carts-btn {
    margin: 30px 0 0 24px;
    width: 184px;
  }

  .landing-carts-center-btn {
    margin: 30px 0 0 24px;
    width: 184px;
  }

  .landing--carts {
    margin-top: 28px;
  }

  .landing-carts-price- {
    font-size: 30px;
  }

  /*.landing-help-box {*/
  /*    margin: 25px 0;*/
  /*}*/
  .landing-carts--txts {
    font-size: 11px;
  }

  .landing-footer--imageTxt- {
    margin-left: 0;
  }
}

@media screen and (max-width: 897px) {
  .landing-carts-second-cart {
    margin: 0;
  }
}

@media screen and (max-width: 765px) {
  .landing-page-background-center-fram {
    transform: translateX(-2vw);
  }
  .store_links {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 425px) {
  .landing-footer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  .landing-footer__section img {
    object-fit: cover;
  }
}

@media screen and (max-width: 1000px) and (min-width: 800px) {
  .landing-page-iphone12 {
    height: 500px;
    width: 500px;
  }
}

@media screen and (max-width: 800px) {
  .landing-page-iphone12 {
    height: 400px;
    width: 400px;
  }

  .landing-head-cart-center {
    margin: 0 4px;
  }
}

@media screen and (max-width: 880px) {
  .landing-page-box-images {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 189px;
  }
}

@media screen and (max-width: 800px) and (min-width: 700px) {
  .landing-page-background-center-fram {
    width: 100vw;
  }

  .landing-page-img-account {
    left: 244px;
    top: 142px;
  }

  .landing-page-center-fram-account2 {
    margin-left: 3vw;
  }
}

@media screen and (max-width: 1080px) and (min-width: 1000px) {
  .landing-head-cart-center {
    margin: 0 4px;
  }
}

/*
mobile responsive
*/
@media screen and (max-width: 480px) {
  .ant-typography h1,
  div.ant-typography-h1,
  div.ant-typography-h1 > textarea,
  h1.ant-typography {
    width: 380px;
    font-size: 7vw;
  }

  .landing-page-center-fram-account2 {
    right: 50vw;
  }

  .landing-page-img-account {
    left: 239px;
    top: 167px;
  }

  .landing-text {
    font-size: 3vw;
  }

  .landing-page-head-bts {
    flex-direction: column;
  }

  .ant-btn.ant-btn-block {
    width: 95vw;
    margin: 10px 0;
  }

  .Landing-head-btn {
    width: 95vw;
  }
}

@media screen and (max-width: 700px) {
  .landing-section--carts {
    flex-direction: column;
    align-items: center;
  }

  .landing-head-cart-center {
    margin: 10px 0;
  }

  .landing-head-cart-title {
    height: 24px;
  }

  .landing-page-background-center-fram {
    flex-direction: column;
  }

  .landing--carts {
    flex-direction: column;
    align-items: center;
  }

  .landing-carts-second-cart- {
    margin: 28px 0;
  }
}

@media screen and (max-width: 644px) {
  .landing-footer {
    display: flex;
    flex-direction: column;
  }

  .landing-footer__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landing-footer__section__wrapper {
    margin-top: 32px;
    justify-content: center;
  }

  .landing-footer_section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 35px;
  }

  .landing-help-box-First-text {
    font-size: 1.1rem;
  }

  .landing-help-box-Second-text {
    font-size: 1.1rem;
  }
}
