.header-layout {
    border-bottom: 1px #D8D8D8 solid;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

#nav-loga {
    width: 200px;
}

.support-text {
    color: white;
}

.support-textA {
    padding-right: 50px;
    color: white;
}

.support-textM {
    color: white;
}

.support-textL {
    color: white;
}

.logo-header-loanthem {
    margin-left: 6%;

}

/*.header-text {*/
/*    display: flex;*/
/*}*/

.header-btn {
    gap: 10px;
    width: 88px;
    height: 35px;
    background: #026BFF;
    border-radius: 4px;
    color: white;
    border: 1px #026BFF solid;
    text-align: center;
}

.header-btn:hover {
    color: white;
    background: #026BFF;
}

.landing-header--sections-btns {
    margin-left: 6%;

}

.landing-header--sections-btns-register {
    margin-right: 50px;

}

@media screen and (max-width: 912px) {
    .support-textM, .support-textA {
        zoom: 110%;
        display: none;
    }

    .logo-header-loanthem {
        padding-left: 0;
    }

    .header-text {
        margin-right: 0;
    }

    .ant-layout-header {
        padding: 0 15px;

    }
}

@media screen and (max-width: 483px) {
    .ant-layout-header {
        padding: 0 13px;

    }

    .landing-header--sections {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .logo-header-loanthem {
        margin-left: 0;

    }

    .landing-header--sections-btns {
        margin-left: 0;
    }

    .landing-header--sections-btns-register {
        margin-right: 38px;
    }

    #nav-loga {
        width: 187px;
    }
    .header-btn{
        width: 74px;

    }
}
