.general-draft {
  @include flex(column, center, center);

  @include __element(header) {
    @include font(24, 700);
    color: #ffffff;
    text-align: center;
    margin-bottom: 24px;
  }

  @include __element(wrapper) {
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #202848;    
    padding: 64px;
    min-width: 75vw;
    width: 100%;
    @include flex(column, space-between, center);    

    @include mobileStyle{
      padding: 8px;
    }

    @include __element(title) {
      @include font(24, 700);
      color: #ffffff;
      text-align: center;
      margin-bottom: 16px;
    }

    @include __element(content) {
      @include font(16, 600);
      color: #ffffff;
      text-align: center;
      margin-bottom: 32px;
    }

    @include __element(header) {
      @include font(24, 700);
      color: #ffffff;
      text-align: center;
      margin-bottom: 24px;
    }

    @include __element(main) {
      width: 100%;

      @include __element(content) {
        padding: 0 40px;
      }
    }

    @include __element(footer) {
      margin-top: 32px;
      @include font(14, 700);
      color: #ffffff;
    }
  }
}
