.login-register-modal {
  .ant-modal-content {
    background: linear-gradient(
      156.1deg,
      hsla(0, 0%, 100%, 0.28) -6.77%,
      hsla(0, 0%, 100%, 0.105) 30.71%
    );
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(68px);
    border-radius: 8px;
    padding: 32px 0;
  }

  @include __element(header) {
    color: #fff;
    @include font(24, 600);
    width: 100%;
    text-align: center;
  }

  @include __element(items) {
    @include flex(column, center, center);
    gap: 16px;
    width: 100%;

    @include __element(item) {
      @include flex(column, center, flex-start);
      gap: 4px;
      width: 100%;

      @include __element(title) {
        color: #fff;
        @include font(14, 400);

        span {
          color: #c04e4d;
        }
      }

      @include __element(error) {
        @include font(14, 400);
        color: #c04e4d;
      }
    }

    @include __element(text) {
      color: #fff;
      user-select: none;

      @include __element(link) {
        color: #026bff;
        cursor: pointer;
      }
    }
  }
}
