.upload-box {
  position: relative;
  @include __element(wrapper) {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid #a4b3f4;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include __element(icons) {
    position: absolute;
    bottom: 0;
    right: 16px;
    background-color: #fff;
    border: 1px solid #a4b3f4;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    @include flex(column, center, center);
  }

  @include __element(items) {
    @include flex(column, center, center);
    width: 100%;
    gap: 4px;

    @include __element(item) {
      @include flex(row, center, center);
      gap: 4px;
      width: 100%;
      border-radius: 100px;
      border: 1px solid #a4b3f4;
      height: 48px;
      color: #fff;
      user-select: none;

      svg {
        cursor: pointer;
      }
    }
  }
}

.upload-box-document {
  width: 100%;
  padding: 8px 0px;
  border-radius: 4px;
  border: 1px dashed #026bff;
  background: #202848;
  cursor: pointer;
  height: 36px;
  @include flex(column, center, center);

  @include __element(content) {
    @include flex(row, center, center);
    gap: 16px;

    svg {
      path,
      fill {
        fill: #026bff;
      }
    }
    span {
      @include font(14, 400);
      color: #fff;
    }
  }
}
