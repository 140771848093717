.profile-loan {
  @include flex(column, center, center);
  gap: 16px;
  cursor: pointer;
  user-select: none;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  @include __element(image-wrapper) {
    height: 88px;
    width: 88px;
    border-radius: 100px;
    overflow: hidden;
    background-color: #202848;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include __element(title) {
    text-align: center;
    @include font(14, 500);
    color: #fff;
    opacity: 0.5;

    &.active {
      @include font(16, 600);
      opacity: 1;
    }
  }
}
