.support-ticket-page {
  @include __element(main-wrapper) {
    margin-top: 32px;
    min-width: 160px;
    max-width: 100%;
    width: 100%;
    @include flex(column, center, center);
    gap: 16px;

    @include __element(upload) {
    }

    @include __element(form) {
      margin-top: 16px;
      @include flex(column, center, center);
      width: 100%;
      gap: 16px;

      @include __element(item) {
        width: 100%;
        @include flex(column, flex-start, flex-start);
        gap: 8px;

        .ant-select {
          width: 100%;
        }

        @include __element(radio) {
          width: 100%;
          @include flex(row, space-between, center);
          .ant-radio-wrapper {
            color: #fff !important;
          }
        }

        @include __element(header) {
          @include font(12, 400);
          color: #fff;
          span {
            @include font(12, 500);
            color: #de2d2d;
          }
        }

        @include __element(error) {
          @include font(12, 400);
          color: #de2d2d;
        }
      }
    }
  }
}
