.payment-installment-page {
  @include __element(preview) {
    @include __element(spin) {
      text-align: center;
    }
  }

  @include __element(lender) {
    @include __element(header) {
      @include font(16, 700);
      color: #fff;
    }

    @include __element(wrapper) {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #fff;
      padding: 8px;
      @include flex(row, space-between, center);
      margin-bottom: 32px;

      @include __element(buttons) {
        color: #fff;
        font-size: 32px;
        @include flex(row, center, center);
        gap: 8px;

        svg {
          cursor: pointer;
        }
      }

      @include __element(count) {
        @include font(17, 500);
        color: #fff;
      }
    }

    .styled-button {
      width: 100%;
    }
  }
}

.payment-info {
  @include flex(column, flex-start, flex-start);
  width: 100%;
  margin-bottom: 16px;
  user-select: none;

  &.GREEN {
    * {
      color: #188a63;
    }
  }

  &.RED {
    * {
      color: #c04e4d;
    }
  }

  &.GRAY {
    * {
      color: #616161;
    }
  }

  &.BLACK {
    * {
      color: #fff;
    }
  }

  @include __element(line) {
    @include flex(row, space-between, center);
    gap: 8px;
    width: 100%;

    @include __element(title) {
      @include font(16, 500);
      min-width: max-content;
    }

    @include __element(amount) {
      @include font(16, 500);
      min-width: max-content;
    }

    @include __element(divider) {
      width: 100%;
      &.GREEN {
        border-top: dotted 1px #188a63;
      }

      &.RED {
        border-top: dotted 1px #c04e4d;
      }

      &.GRAY {
        border-top: dotted 1px #616161;
      }

      &.BLACK {
        border-top: dotted 1px #fff;
      }
    }
  }
}

.sign-modal {
  @include flex(column, center, center);

  @include __element(header) {
    @include font(20, 600);
    text-align: left;
  }

  @include __element(header) {
    @include font(16, 400);
    text-align: left;
  }

  @include __element(buttons) {
    @include flex(row, center, center);
    width: 100%;
    gap: 8px;
    margin-top: 16px;
  }
}
