.agreement-form {
    width: 423px;
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 124.71%);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.06);
    -webkit-backdrop-filter: blur(68px);
    backdrop-filter: blur(68px);
    border-radius: 8px;
}

.agreement {
    display: grid;
}

.agreement-formItem-input {
    padding: 0 40px 0 40px;

}

.agreement-form {
    width: 423px;
    height: 385px;
    background: linear-gradient(156.1deg, rgba(255, 255, 255, 0.28) -6.77%, rgba(255, 255, 255, 0.105) 0.71%);
    box-shadow: 0px 4px 16px rgb(255 255 255/6%);
    -webkit-backdrop-filter: blur(185px);
    backdrop-filter: blur(185px);
    border-radius: 8px;
    opacity: 1.5;
    border: 1px solid rgba(18, 54, 110, 0.6);
    position: relative;
}

/*.agreement-input-date-inp ::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    left: 50%;*/
/*    margin-left: -5px;*/
/*    border-width: 5px;*/
/*    border-style: solid;*/
/*    border-color: black transparent transparent transparent;*/
/*}*/

.agreement-text {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    align-items: center;
    text-align: center;
}


.agreement-form .ant-form-item-required::after {
    visibility: hidden;
    content: none;
    color: blue;

}

.agreement-form .ant-form-item-label > label {
    color: white;
}

.agreement-form .ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: white;
}

.agreement-form .ant-form-item-required::before {
    margin-left: 8px;
}


.agreement-form .ant-form-item-explain-error {
    color: #c04e4d;
}

.agreement-form-line {
    border-top: 1px solid rgba(164, 179, 244, 1);
    opacity: 0.3;
}

.agreement-input-date .ant-picker-dropdown .ant-picker-dropdown-placement-bottomLeft {
    position: absolute !important;
    left: 100px !important;

}

.agreement-form-btn {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    width: 343px;
    height: 44px;
    background: #026BFF;
    border-radius: 4px;
    padding: 0 40px 0 40px;
    margin: 21px 0 0 37px;
}


.agreement-input-date {
    padding: 0 40px 0 40px;
}

.agreement-form .ant-form-item-label > label:after {
    display: none;
}

/*.agreement-input-date-inp {*/
/*    width: 343px;*/
/*    height: 40px;*/
/*}*/

.date-pick-test-:after {
    content: url('src/assets/images/arrow-head.png');
    position: absolute;
    top: 300px;
    left: 13px;
}

.date-pick-test:before {
    content: url('src/assets/images/arrow-head-rev.png');
    position: absolute;
    top: -16px;
    left: 13px;
}

@media screen and (max-width: 780px) {
    .lump-input-date-inp {
        width: 212px;
    }

    .agreement-text{
        text-align: end;
    }

    .note-default {
        justify-content: space-around;
    }

    .lump-input-date-inp {
        width: 216px;
    }

    .agreement-form {
        width: 270px;
        margin: 0;
    }

    .agreement-form-btn {
        width: 212px;
        margin: 30px 0 0 31px;
    }

    .agreement-form {
        
        height: 438px;

    }
}

@media screen and (max-width: 500px) {
    .lump-input-date-inp {
        width: 190px;
    }

    .agreement-form-btn {
        width: 190px
    }
    .ant-form-item-label>label {
    font-size: 12px;
    }

    }

@media screen and (max-width: 300px) {
    .agreement-form {
        margin-left: 0;
        height: 438px;

    }
}
