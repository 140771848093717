.lateFee-two-inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

@media screen and (max-width: 577px) {
    .lateFee-two-inputs {
        flex-direction: column;
    }

    .lateFee-two-inputs {
        margin: 5px 0;

    }
    .row-input{
        margin: 5px 0;


    }  }
