.general-form {
  @include flex(column, center, center);  

  @include __element(header) {
    @include font(24, 700);
    color: #ffffff;
    text-align: center;
    margin-bottom: 24px;
  }

  @include __element(wrapper) {
    box-sizing: content-box;
    border-radius: 8px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.28) 0%,
      rgba(255, 255, 255, 0.11) 100%
    );
    box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(34px);
    min-width: 424px;    
    @include flex(column, space-between, center);

    @include mobileStyle {
      width: 100%;
      min-width: 100%;    
    }

    @include __element(main) {
      width: 100%;

      @include __element(header) {
        @include font(14, 14);
        color: #ffffff;
        text-align: center;
        margin: 24px 0 16px;
        padding: 0 40px;
      }
  
      @include __element(content) {
        padding: 0 40px;
      }            
    }    

    @include __element(submit) {
      width: 100%;
      padding-bottom: 24px;

      @include __element(divider) {
        height: 1px;
        width: 100%;
        background-color: #A4B3F4;
        margin: 24px 0;
      }

      @include __element(wrapper) {
        width: 100%;
        padding: 0 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        button{                  
          width: 100%;   
          max-width: 240px;           
          margin: auto;    
        
        } 
      }      
    }      
  }
}
