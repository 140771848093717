.demand-letter-page {
  @include __element(preview) {
    @include __element(spin) {
      text-align: center;
    }

    @include __element(buttons) {
      margin-top: 16px;
      @include flex(row, flex-end, center);
      gap: 8px;
    }
  }
}
