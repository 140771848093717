.about--title{
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;

}
.about--txt{
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.about--container{
width: 100%;
display: flex;
justify-content: center;
margin-top: 104px

}

.about-image-container{

    margin-top: 139px;
}