.timer {
  @include __element(active) {
    @include flex(row, center, center);
    @include font(14, 500);
    gap: 8px;
    color: #fff;
    user-select: none;

    span {
      margin-top: 4px;
    }
  }

  @include __element(button-wrapper) {
    @include flex(row, center, center);    
  }
}
