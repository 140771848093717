.dashboard-page {
  @include __element(wrapper) {
    margin-top: 80px;

    @include __element(content) {
      @include flex(row, flex-start, stretch);
      margin-top: 80px;
      gap: 8px;

      @include tabletStyle {
        flex-wrap: wrap;
      }

      @include smallDesktopStyle {
        flex-wrap: wrap;
      }

      @include __element(info) {
        @include flex(row, space-between, center);

        @include tabletStyle {
          flex-wrap: wrap;
          width: 100%;
        }        

        @include smallDesktopStyle {
          flex-wrap: wrap;
          width: 100%;
        }        

        .loan-info {
          width: 402px;
          height: 100%;

          @include tabletStyle {
            width: 100%;
          }

          @include smallDesktopStyle {
            width: 100%;
          }
        }
      }

      @include __element(links) {
        width: 100%;
        padding: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 8px;
        grid-column-gap: 32px;
        border-radius: 8px;
        background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0.28) 0%,
          rgba(255, 255, 255, 0.11) 100%
        );
        box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(34px);

        @include mobileStyle {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

.demand-letter-modal {
  @include flex(column, center, center);

  @include __element(header) {
    @include font(20, 600);
    text-align: center;
  }

  @include __element(buttons) {
    @include flex(column, center, center);
    margin-top: 32px;
    width: 100%;
    gap: 8px;

    @include __element(button) {
      @include flex(column, flex-start, center);
      padding: 12px 24px;
      color: #2b3a4a;
      border-radius: 12px;
      background: #eaebec;
      width: 100%;
      cursor: pointer;
      user-select: none;

      @include __element(header) {
        @include font(16, 800);
      }

      @include __element(description) {
        @include font(12, 600);
      }
    }
  }
}

.premium-modal {
  @include flex(column, center, center);

  @include __element(header) {
    @include font(20, 600);
    text-align: left;
  }

  @include __element(header) {
    @include font(16, 400);
    text-align: left;
  }

  @include __element(buttons) {
    @include flex(row, flex-end, center);
    width: 100%;
    gap: 8px;
  }
}
