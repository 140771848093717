.admin_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  background-image: url("../../assets/images/login-background.png");
  /*   background-color: red;
 */
 background-position: center;
}
.admin_row {
  display: flex;
  width: 100%;
  height: 100%;
}
.admin_sidebar_container {
  width: 30%;
  height: 100vh;
  /*   background-color: aqua;
 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin_sidebar {
  height: 80%;
  width: 60%;
  border-radius: 30px;
  /*   background-color: rgb(0, 9, 47);
*/
  box-shadow: 0px 4px 16px rgb(255 255 255/6%);
  -webkit-backdrop-filter: blur(185px);
  backdrop-filter: blur(185px);
  opacity: 1.6;
  border: 1px solid rgba(70, 144, 255, 0.6);

  display: flex;
  flex-direction: column;
}
.sidebar_header {
  text-align: center;
  margin-top: 37px;
}
.sidebar_header h2 {
  color: white;
  cursor: pointer;
}
.sidebar_header hr {
  width: 70%;
}
.sidebar_content_container {
  height: 100%;
  width: 100%;
  /*   background-color: red;
 */
  display: flex;
  justify-content: center;
  border-radius: 30px;
}
.admin_sidebar_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin_sidebar_list_items {
  margin-top: 80px;
  cursor: pointer;
  color: white;
  font-size: 1.3rem;
}
.admin_content_container {
  width: 70%;
  height: 100vh;
  /*   background-color: green;
 */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.admin-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.admin-modal-container {
  width: 70%;
  height: 70%;
  background-color: white;
  border-radius: 50px;
}

/* new */

.admin-navbar {
  width: 100vw;
  height: 64px;
  background-color: #202848;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4%;
}

.admin-navbar span {
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px;
}
.selected {
  border: 1px solid blue;
  border-radius: 15px;
}
.admin_content_container {
  width: 90%;
  height: 100%;
  margin: auto;
  /*   height: 100vh;
 */
  /*   background-color: green;
 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
.ant-table-tbody > tr > td {
  background-color: white;
}
.my-table {
  border-radius: 8px 8px 0 0;
}
/* .ant-table-tbody > tr > td:last-child{
  border-bottom-right-radius: 5px;
}
 */
 .ant-table-tbody > tr > td{
  border-left: 1px solid #f0f0f0;
 }
 .ant-table-thead > tr > th{
  border-left: 1px solid #f0f0f0;

 }
 .ant-table-tbody > tr:last-child > td:last-child {
  border-left: 1px solid #f0f0f0;
  border-bottom-right-radius: 3px;
 }
 .ant-table-tbody > tr:last-child > td:first-child {
  border-left: 1px solid #f0f0f0;
  border-bottom-left-radius: 30px;
 }
 .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis{
  color: white;
 }
 .ant-table-pagination{
  gap: 5px;
 }
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  width: 0;
}
@media screen and (max-width: 1024px) {
  .admin_content_container {
    margin-top: 80px;
  }
}
