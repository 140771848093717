.notification-main-wrapper {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.notification-icon {
  @include __element(button) {
    @include flex(row, flex-end, center);
    gap: 8px;
    cursor: pointer;
    user-select: none;

    span {
      @include font(14, 400);
      color: #fff;
    }
  }
}

.notification-menu {
  width: 100%;  

  @include __element(spin) {
    padding: 16px;
  }

  ul {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 200px);
    overflow: auto;

    li {
      &.active{
        background-color: #a4b3f4;
      }

      @include flex(row, flex-start, center);
      gap: 8px;
      user-select: none;
      width: 100%;
      margin: 2px 0;
      cursor: pointer;
      padding: 0 8px;
      box-sizing: border-box;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.notification-item {
  @include flex(row, flex-start, flex-start);
  width: 100%;
  gap: 16px;
  padding: 16px 0;

  @include __element(icon-wrapper) {
    min-width: 36px;
  }

  @include __element(text-wrapper) {
    width: 100%;
    @include flex(column, flex-start, flex-start);

    @include __element(title) {
      @include font(14, 500);
      color: #2b3a4a;
    }

    @include __element(date-wrapper) {
      @include flex(row, space-between, center);
      width: 100%;

      @include __element(date) {
        @include font(12, 400);
        color: #969696;
      }
    }
  }
}
