.profile-icon {
  @include __element(button) {
    @include flex(row, flex-end, center);
    gap: 8px;
    cursor: pointer;
    user-select: none;

    span {
      @include font(14, 400);
      color: #fff;
    }
  }
}

.profile-menu {
  width: 100%;
  ul {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      @include flex(row, flex-start, center);
      gap: 8px;
      user-select: none;
      width: 100%;
      height: 32px;
      margin: 2px 0;
      cursor: pointer;

      a {
        @include flex(row, flex-start, center);
        gap: 8px;
        user-select: none;
        width: 100%;
        height: 32px;
        margin: 2px 0;
        cursor: pointer;
      }

      &:hover {
        background-color: #f5f5f5;
      }

      span {
        @include font(14, 400);
        color: #000000d9;
        &.red {
          color: #c04e4d;
        }
      }
    }
  }

  .ant-divider {
    margin: 4px 0 !important;
  }
}
