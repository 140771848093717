.payment-history-page {
  @include __element(body) {
    @include __element(buttons) {
      @include flex(row, space-between, center);
      gap: 24px;
      margin-bottom: 16px;

      @include __element(button) {
        width: 100%;
        text-align: center;
        background-color: #404d5d;
        border-radius: 8px;
        color: #eaebec;
        @include font(17, 700);
        padding: 16px 0;
        user-select: none;
        cursor: pointer;

        &.active {
          color: #000;
          background-color: #eaebec;
        }
      }
    }
  }
  .payment-history-header-contnet-container{
    width: 100%; display: flex;  justify-content: space-between;
  }

  .ant-collapse {
    background-color: transparent;
    border: none;
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-collapse-item {    
    background: transparent;
  }

  .ant-collapse-content {
    background-color: transparent;
  }

  .ant-collapse-header {
    background: linear-gradient(
      190deg,
      rgba(255, 255, 255, 0.28) 0%,
      rgba(255, 255, 255, 0.11) 100%
    );
  }

  .general-draft__wrapper__main{
    text-align: center;
  }
}

.payment-history-header {
  @include flex(row, flex-start, center);
  gap: 16px;
  @include font(16, 600);
  color: #fff;  
}

.payment-history-detail {
  background: linear-gradient(
    190deg,
    rgba(255, 255, 255, 0.28) 0%,
    rgba(255, 255, 255, 0.11) 100%
  );
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  
  @include flex(column, flex-start, flex-start);
  gap: 8px;

  @include __element(row) {
    width: 100%;
    @include flex(row, space-between, center);

    @include __element(label) {
      @include font(16, 600);
      color: #fff;
      min-width: 150px;
      text-align: left;
    }

    @include __element(value) {
      @include font(15, 400);
      color: #fff;
      word-break: break-all;
      text-align: right;
    }
  }
}
.payment_empty{
  margin-top: 25px;
h5{
font-size: 1.2rem;
color: red;
font-weight: 800;
letter-spacing: 1px;
}

}