.loan-info {
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0.11) 100%);
  box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(34px);
  padding: 24px;
  position: relative;

  @include __element(header) {
    @include flex(row, space-between, center);

    @include __element(section) {
      @include flex(column);

      @include __element(title) {
        @include font(14, 400);
        color: #a4b3f4;
      }

      @include __element(value) {
        @include font(32, 700);
        color: #ffffff;

        span{
          @include font(22, 600);          
        }
      }
    }
  }

  @include __element(body) {
    @include flex(column);
    gap: 24px;
    margin-top: 8px;
  }

  @include __element(buttons) {
    position: absolute;
    bottom: 16px;
    right: 16px;
    left: 16px;
    @include flex(column);
    margin-top: 16px;
    gap: 8px;

    @include tabletStyle {
      position: relative;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}

.loan-info-line {
  @include flex(row, space-between, center);
  gap: 8px;

  @include __element(title) {
    @include font(14, 500);
    color: #ffffff;
    min-width: max-content;
  }

  @include __element(amount) {
    @include font(14, 700);
    color: #ffffff;
    min-width: max-content;
  }

  @include __element(divider) {
    border-top: dotted 1px #a4b3f4;
    width: 100%;
  }
}
